<template>
	<div class="bg-cover bg-no-repeat" :style="cssProps">
	  <div class="container mx-auto px-6 pb-10 pt-44">
			<img class="w-32 py-8 pt-96" src="@/assets/firetool.png" alt="image">
			<p class="text-white font-bold text-7xl">Firetool</p>
	  </div>
	</div>

	<div class="container mx-auto px-6 pb-10 mt-8" id="accordion-collapse" data-accordion="collapse">
		<h2 id="accordion-collapse-heading-1">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
				<span class="text-2xl font-semibold" style="color: #081414">Focos de calor</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Detecta las áreas que poseen un comportamiento térmico anómalo en comparación con su entorno.</p>
					<p class="pb-5">Permite tomar medidas paliativas por las autoridades del sistema de monitoreo y prevención de incendios.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-2">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
				<span class="text-2xl font-semibold" style="color: #081414">Áreas quemadas</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Mapa periódico de zonas donde hubo incendios con un registro anterior de diez años.</p>
					<p class="pb-5">Desarrollado por Land Processes Distributed Archive Centera con un sensor MODIS versión 6 (MOD64A1).</p>
					<p class="pb-5">Resolución espacial de 500 m.</p>
					<p class="pb-5">Resolución temporal mensual.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-3">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
				<span class="text-2xl font-semibold" style="color: #081414">Frecuencia de áreas quemadas</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Genera información histórica mensual de los incendios sucedidos en un mismo predio desde el año 2000.</p>
					<p class="pb-5">Se muestran áreas mayores a 2 ha.</p>
					<p class="pb-5">Resolución a 1 km.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-4">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
				<span class="text-2xl font-semibold" style="color: #081414">FWI - Índice de riesgo meteorológico de incendios forestales </span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Índice FWI (Fire Weather Index) utilizado globalmente.</p>
					<p class="pb-5">Modelo de predicción meteorológica Global GFS desarrollado por NCEP.</p>
					<p class="pb-5">Pronóstico meteorológico de humedad, temperatura, velocidad del viento y precipitación y acumulación de agua.</p>
					<p class="pb-5">Actualización diaria de datos.</p>
					<p class="pb-5">Proyección de riesgo para los próximos 3 días.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Firetool',
	data() {
		return {
			cssProps: {
				'backgroundImage': `url(${require('@/assets/firetool-bg.png')})`,
			}
		}
	}
}
</script>

<style scoped>
.rectangle {
	height: 300px;
	width: 550px;
	background-color: #081414;
}
</style>
