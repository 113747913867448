<template>
	<header style="background-color: #081414">
		<div class="container mx-auto pt-6 pb-6 px-6 py-3">
			<div class="flex items-center">
				<router-link to="/"><img style="width: 600px" src="@/assets/logo.png" alt="image"></router-link>
				<div class="container flex justify-end">
					<div class="flex justify-end relative">
								<span class="absolute inset-y-0 left-0 pl-3 flex items-center">
									<svg class="h-5 w-5 text-gray-500" viewBox="0 0 24 24" fill="none">
											<path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</span>
						<input class="placeholder-black border w-36 rounded-md pl-10 mr-4 pr-4 py-2 focus:border-blue-500 focus:outline-none focus:shadow-outline" type="text" placeholder="Buscar">
					</div>
					<div class="flex items-center relative">
						<button class="flex items-center h-12 w-36 rounded-md" style="background-color: #384444">
							<img src="@/assets/lock.png" class="pl-6 pr-3" alt="image">
							<span class="flex items-center text-white">Ingresar</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: "headerApp"
}
</script>

<style scoped>

</style>
