<template>
	<div class="bg-cover bg-no-repeat" :style="cssProps">
	  <div class="container mx-auto px-6 pb-10 pt-44">
			<img class="w-32 py-8 pt-96" src="@/assets/thinktool.png" alt="image">
			<p class="text-white font-bold text-7xl">Thinktool</p>
	  </div>
	</div>

	<div class="container mx-auto px-6 pb-10 mt-8">
		<p class="text-3xl font-semibold pb-10">Es la plataforma flexible de TOOLSAT que permite sumar o desarrollar nuevos productos.</p>
		<p class="text-4xl font-semibold pt-8">Sumate</p>
	  <ul class="list-disc pl-10 pt-8">
			<li class="text-2xl pb-8">Si has desarrollado una nueva herramienta y deseas compartirla con potenciales usuarios de la región, <p class="font-semibold" style="color: #562ba5">contáctanos.</p></li>
			<li class="text-2xl pb-8">Si deseas subir nuevas capas de información a productos existentes creando nuevas herramientas o tienes sugerencias que sumen valor agregado, <p class="font-semibold" style="color: #562ba5">contáctanos.</p></li>
			<li class="text-2xl pb-8">Si precisas utilizar esta plataforma para brindarle a tus clientes un servicio realiza tu consulta, <p class="font-semibold" style="color: #562ba5">contáctanos.</p></li>
			<li class="text-2xl pb-8">Si tienes una idea que te gustaría llevar adelante pero no cuentas con el suficiente conocimiento puedes solicitarnos asistencia, <p class="font-semibold" style="color: #562ba5">contáctanos.</p></li>
		</ul>
	  <p class="text-4xl font-semibold pt-8 pb-8">Thinktool te brinda:</p>
		<p class="text-2xl pb-8">Un <span class="text-2xl font-semibold">espacio</span> de intercambio entre usuarios e instituciones.</p>
		<p class="text-2xl pb-8">Una <span class="text-2xl font-semibold">plataforma</span> para el desarrollo de tus ideas.</p>
		<p class="text-2xl pb-8">Una <span class="text-2xl font-semibold">COMUNIDAD</span> regional que involucra a varios países.</p>
		<p class="text-2xl pb-8">Una oportunidad para el <span class="text-2xl font-semibold">crecimiento profesional,</span> a través del desarrollo de nuevas aplicaciones o mejora de las actuales.</p>
		<p class="text-2xl pb-8">Un <span class="text-2xl font-semibold">equipo de profesionales e investigadores</span> de diversas disciplinas especializados en la observación remota y los datos satelitales como insumo para construir soluciones a medida.</p>
	  <p class="text-4xl font-semibold pt-8">Nuestro desafío</p>
	  <ul class="list-disc pl-10 pt-8">
		  <li class="text-2xl pb-8">seguir investigando,</li>
		  <li class="text-2xl pb-8">fomentar la creación de nuevos recursos técnicos especializados,</li>
		  <li class="text-2xl pb-8">crear futuras soluciones a medida para clientes y organizaciones diversas.</li>
	  </ul>
	</div>
</template>

<script>
export default {
	name: 'Thinktool',
	data() {
		return {
			cssProps: {
				'backgroundImage': `url(${require('@/assets/thinktool-bg.png')})`,
			}
		}
	}
}
</script>

<style scoped>
.rectangle {
	height: 300px;
	width: 550px;
	background-color: #081414;
}
</style>
