import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Firetool from '../views/Firetool.vue'
import Healthtool from "@/views/Healthtool";
import Hydrictool from "@/views/Hydrictool";
import Thinktool from "@/views/Thinktool";
import Agrotool from "@/views/Agrotool";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/firetool',
    name: 'Firetool',
    component: Firetool
  },
  {
    path: '/healthtool',
    name: 'Healthtool',
    component: Healthtool
  },
  {
    path: '/hydrictool',
    name: 'Hydrictool',
    component: Hydrictool
  },
  {
    path: '/thinktool',
    name: 'Thinktool',
    component: Thinktool
  },
  {
    path: '/agrotool',
    name: 'Agrotool',
    component: Agrotool
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
