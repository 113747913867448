<template>
	<div>
		<main>
			<div class="px-36 pt-8 pb-8" :style="cssProps">
				<h1 class="text-7xl font-bold text-white">SISTEMA INTEGRADO</h1>
				<h1 class="text-7xl font-bold text-white pb-80">DE INFORMACIÓN SATELITAL</h1>
				<p class="text-4xl text-white">Una herramienta versátil de uso libre</p>
				<p class="text-4xl text-white">que te brinda información precisa</p>
				<p class="text-4xl text-white">en tiempo real</p>
			</div>
			<div class="px-6 pt-8" style="background-color: #e8e4e4">
				<div class="container mx-auto px-6 pb-10">
					<h1 class="text-6xl mb-6 mt-8">PRODUCTOS</h1>
					<p class="text-4xl">Toolsat posee multifunciones diseñadas</p>
					<p class="text-4xl">según el área específica de tu interés</p>
					<p class="text-4xl">o emprendimiento, a las que podrás acceder</p>
					<p class="text-4xl">de manera gratuita</p>
				</div>
			</div>
			<div>
				<div class="h-64" style="background-color: #dca424">
					<div class="flex items-center h-full">
						<router-link class="pl-36" to="/firetool"><img class="w-32" src="@/assets/firetool.png" alt="image"></router-link>
						<div class="pl-10">
							<router-link to="/firetool" class="text-5xl text-white font-semibold">Firetool</router-link>
							<p class="text-white text-2xl pt-3">Un set de herramientas pensado tanto para el monitoreo</p>
							<p class="text-white text-2xl">de eventos y la detección temprana de los riesgos</p>
							<p class="text-white text-2xl">de incendios, como para el análisis histórico de áreas</p>
							<p class="text-white text-2xl">quemadas.</p>
						</div>
					</div>
				</div>
				<div class="h-64" style="background-color: #82A821">
					<div class="flex items-center h-full">
						<router-link class="pl-36" to="/agrotool"><img class="w-32" src="@/assets/agrotool.png" alt="image"></router-link>
						<div class="pl-10">
							<router-link to="/agrotool" class="text-5xl text-white font-semibold">Agrotool</router-link>
							<p class="text-white text-2xl pt-3">Un aliado en el seguimiento de la producción agroextensiva</p>
							<p class="text-white text-2xl">que permite el registro de los eventos que tú consideres</p>
							<p class="text-white text-2xl">relevantes dentro del ciclo agrícola, brindando la información</p>
							<p class="text-white text-2xl">necesaria en tiempo real.</p>
						</div>
					</div>
				</div>
				<div class="h-64" style="background-color: #1CB494">
					<div class="flex items-center h-full">
						<router-link class="pl-36" to="/healthtool"><img class="w-32" src="@/assets/healthtool.png" alt="image"></router-link>
						<div class="pl-10">
							<router-link to="/healthtool" class="text-5xl text-white font-semibold">Healthtool</router-link>
							<p class="text-white text-2xl pt-3">Mapa ambiental que permite analizar las diferentes variables</p>
							<p class="text-white text-2xl">atmosféricas e identificar las áreas con mayor o menor riesgo de</p>
							<p class="text-white text-2xl">desarrollo del vector que transmite el dengue, zika y</p>
							<p class="text-white text-2xl">chikungunya.</p>
						</div>
					</div>
				</div>
				<div class="h-64" style="background-color: #0E28A5">
					<div class="flex items-center h-full">
						<router-link class="pl-36" to="/hydrictool"><img class="w-32" src="@/assets/hydrictool.png" alt="image"></router-link>
						<div class="pl-10">
							<router-link to="/hydrictool" class="text-5xl text-white font-semibold">Hydrictool</router-link>
							<p class="text-white text-2xl pt-3">Es un sistema de alerta hídrica que permite el monitoreo y</p>
							<p class="text-white text-2xl">seguimiento de los espejos de agua y la evaluación de daños</p>
							<p class="text-white text-2xl">frente a inundaciones.</p>
						</div>
					</div>
				</div>
				<div class="h-64" style="background-color: #753ADF">
					<div class="flex items-center h-full">
						<router-link class="pl-36" to="/thinktool"><img class="w-32" src="@/assets/thinktool.png" alt="image"></router-link>
						<div class="pl-10">
							<router-link to="/thinktool" class="text-5xl text-white font-semibold">Thinktool</router-link>
							<p class="text-white font-semibold text-2xl pt-3">Apostando al desarrollo tecnológico y comunitario</p>
							<p class="text-white font-semibold text-2xl">de aplicaciones satelitales</p>
							<p class="text-white text-2xl pt-3">Plataforma base para la creación y el desarrollo de nuevas</p>
							<p class="text-white text-2xl">herramientas que sigan generando valor y conocimiento</p>
							<p class="text-white text-2xl">a distintas áreas.</p>
						</div>
					</div>
				</div>
				<div class="px-6 pt-36" style="background-color: #e8e4e4">
					<div class="container mx-auto px-6 pb-10">
						<h1 class="text-5xl font-bold pb-3">EL PROYECTO TOOLSAT</h1>
						<div class="grid grid-flow-col auto-cols-auto">
							<p class="text-2xl">Toolsat surgió como consecuencia del esfuerzo colectivo de varios países de América Latina. El primer objetivo fue la creación del Sistema Integrado Regional de Información Satelital (SIRIS). Una plataforma que permitiera el acceso a imágenes y datos satelitales de manera gratuita, para mejorar la toma de decisiones de diferentes sectores productivos, sociales y ambientales.</p>
							<p class="text-2xl pl-14">Convertir dicha plataforma en una herramienta para especialistas pero también para personas sin ninguna experiencia ni conocimientos previos en información satelital, fue el siguiente gran paso de esta iniciativa, que dio lugar al nacimiento de Toolsat.</p>
						</div>
						<div class="grid grid-cols-8 flex items-center">
								<div/><div/><div/><div/>
								<p class="text-2xl font-bold">Argentina</p>
								<p class="text-2xl font-bold">Bolivia</p>
								<p class="text-2xl font-bold">Chile</p>
								<p class="text-2xl font-bold">Ecuador</p><div/>
								<img class="container w-48" src="@/assets/bid.png" alt="image"><div/><div/>
								<div class="rectangle-home"></div>
								<div class="rectangle-home"></div>
								<div class="rectangle-home"></div>
								<div class="rectangle-home"></div><div/>
								<img class="container w-56" src="@/assets/oas.gif" alt="image"><div/><div/>
								<div class="rectangle-home"></div><div/>
								<div class="rectangle-home"></div><div/><div/><div/><div/><div/>
								<p class="text-2xl font-bold pt-6">México</p>
								<p class="text-2xl font-bold pt-6">Paraguay</p>
								<p class="text-2xl font-bold pt-6">Perú</p>
								<p class="text-2xl font-bold pt-6">Uruguay</p><div/><div/><div/><div/>
								<div class="rectangle-home mt-3"></div>
								<div class="rectangle-home mt-3"></div>
								<div class="rectangle-home mt-3"></div>
								<div class="rectangle-home mt-3"></div><div/><div/><div/><div/><div/>
								<div class="rectangle-home mt-6"></div>
								<div class="rectangle-home mt-6"></div>
								<div class="rectangle-home mt-6"></div><div/><div/><div/><div/><div/><div/><div/>
								<div class="rectangle-home mt-6"></div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
  name: 'Home',
	data() {
		return {
			cssProps: {
				backgroundImage: `url(${require('@/assets/main.png')})`
			}
		}
	}
}
</script>

<style>
.rectangle-home {
	height: 40px;
	width: 120px;
	background-color: #555;
}
</style>
