<template>
	<div class="bg-cover bg-no-repeat" :style="cssProps">
	  <div class="container mx-auto px-6 pb-10 pt-44">
			<img class="w-32 py-8 pt-96" src="@/assets/hydrictool.png" alt="image">
			<p class="text-white font-bold text-7xl">Hydrictool</p>
	  </div>
	</div>

	<div class="container mx-auto px-6 pb-10 mt-8" id="accordion-collapse" data-accordion="collapse">
		<h2 id="accordion-collapse-heading-1">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
				<span class="text-2xl font-semibold" style="color: #081414">Espejos de agua</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Set de productos de carácter regional:</p>
					<ul class="list-disc pl-5">
						<li>Mapeo de los espejos de agua y de las áreas afectadas por inundación</li>
						<li>Análisis histórico con imágenes de los últimos 20 años</li>
					</ul>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-2">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
				<span class="text-2xl font-semibold" style="color: #081414">Nieve</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Detección del área cubierta de nieve, a través de imágenes de cobertura nival de los sensores MODIS Terra y MODIS Aqua. Este producto se actualizará de forma.</p>
					<p class="pb-5">Análisis de datos históricos de la cobertura nival. Muestra la frecuencia de ocurrencia de los eventos para cada pixel.</p>
					<p class="pb-5">Anomalías de la cubierta nival por píxel en relación a la media histórica.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Hydrictool',
	data() {
		return {
			cssProps: {
				'backgroundImage': `url(${require('@/assets/hydrictool-bg.png')})`,
			}
		}
	}
}
</script>

<style scoped>
.rectangle {
	height: 300px;
	width: 550px;
	background-color: #081414;
}
</style>
