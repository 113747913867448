<template>
	<div class="bg-cover bg-no-repeat" :style="cssProps">
	  <div class="container mx-auto px-6 pb-10 pt-44">
			<img class="w-32 py-8 pt-96" src="@/assets/agrotool.png" alt="image">
			<p class="text-white font-bold text-7xl">Agrotool</p>
	  </div>
	</div>

	<div class="h-80 mt-8" style="background-color: #82A821">
	  <div class="container mx-auto px-6 pb-10 pt-10">
			<p class="text-white font-bold text-5xl">SET DE PRODUCTOS</p>
			<p class="text-white font-bold text-5xl pb-8">PARCELARIOS</p>
			<p class="text-white font-bold text-2xl">Monitoreo del crecimiento de tus cultivos</p>
			<p class="text-white text-2xl">Podrás conocer el estado de tus cultivos extensivos desde la siembra hasta la cosecha,</p>
			<p class="text-white text-2xl">con información actualizada periódicamente, a partir de imágenes Sentinel de 10 metros</p>
			<p class="text-white text-2xl">de pixel y Landsat de 30 metros.</p>
		</div>
	</div>
	<div class="container mx-auto px-6 pb-10 mt-8" id="accordion-collapse" data-accordion="collapse">
		<h2 id="accordion-collapse-heading-1">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
				<span class="text-2xl font-semibold" style="color: #081414">Índice verde</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Muestra el vigor de un cultivo y su crecimiento a lo largo de una campaña.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-2">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
				<span class="text-2xl font-semibold" style="color: #081414">Variabilidad</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">Estandariza el estado del cultivo según áreas homogéneas dentro de la parcela, de mayor a menor grados de vigor.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-3">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
				<span class="text-2xl font-semibold" style="color: #081414">Índice verde histórico</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">A través del análisis estadístico de imágenes satelitales históricas de Landsat de 20 años, seleccionadas en épocas donde los cultivos alcanzan su máximo crecimiento es posible:</p>
					<ul class="list-disc pl-5">
						<li>estudiar el  comportamiento histórico de áreas específicas dentro de las parcelas</li>
						<li>hacer muestreos direccionados</li>
						<li>confeccionar mapas de zonas de manejo</li>
						<li>identificar zonas de mayor y menor productividad dentro de la parcela</li>
					</ul>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
	</div>

	<div class="h-34 mt-8" style="background-color: #82A821">
	  <div class="container mx-auto px-6 pb-10 pt-10">
		  <p class="text-white font-bold text-5xl">SET DE PRODUCTOS</p>
		  <p class="text-white font-bold text-5xl pb-8">REGIONALES</p>
		</div>
	</div>
	<div class="container mx-auto px-6 pb-10 mt-8" id="accordion-collapse-2" data-accordion="collapse">
		<h2 id="accordion-collapse-heading-4">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
				<span class="text-2xl font-semibold" style="color: #081414">Sequía</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="font-semibold">Sequía actual</p>
					<p class="pb-5 pb-4">Es calculada a partir de la diferencia del índice de vegetación normalizado (NDVI) y el índice de agua en vegetación normalizado (NDWI).</p>
					<p class="pb-5">Es un producto quincenal, con una resolución espacial de 250 m. que compara el dato de hoy con el histórico del mes.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
				<div>
					<p class="font-semibold">Sequía actual anómala</p>
					<p class="pb-5 pb-4">Indica las zonas en donde hay cambios en las condiciones de sequía que resultan fuera de la normalidad.</p>
					<p class="pb-5">Compara valores históricos de sequía generando cuatro clasificaciones de suelo:</p>
					<ul class="list-disc pl-5">
						<li>sin sequía</li>
						<li>con sequía incipiente</li>
						<li>con sequía moderada</li>
						<li>con sequía extrema.</li>
					</ul>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-5">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
				<span class="text-2xl font-semibold" style="color: #081414">Áreas forestadas</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="font-semibold">Mapas de forestación</p>
					<p class="pb-5 pb-4">Indican la cobertura forestal, tanto natural como implantada.</p>
					<p class="pb-5">Es un producto estático que permite conocer el total forestado para generar análisis posteriores al nivel de planificación del sector forestal.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
				<div>
					<p class="font-semibold">Mapas de deforestación</p>
					<p class="pb-5 pb-4">Informa sobre la existencia de deforestación y permite el análisis de la variación temporal de las superficies boscosas, reforestación y/o degradación del suelo.</p>
					<p class="pb-5">Producto de periodicidad semanal.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-6">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
				<span class="text-2xl font-semibold" style="color: #081414">Heladas</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="font-semibold">Helada histórica</p>
					<p class="pb-5 pb-4">Establece la probabilidad de heladas para cierta área según el mes del año.</p>
					<p class="pb-5">Valores obtenidos a partir de una serie histórica de diez años.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
				<div>
					<p class="font-semibold">Pronóstico de helada</p>
					<p class="pb-5 pb-4">Combina la información histórica sobre las heladas con el pronóstico de temperaturas (WRF/CONAE) para ese día (temperaturas en el entorno de ±5°C).</p>
					<p class="pb-5">Producto de periodicidad diaria.</p>
					<p class="pb-5">Informa al usuario a partir de una proyección hacia las 24 y 48 horas siguientes.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-7">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
				<span class="text-2xl font-semibold" style="color: #081414">Humedad</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
		  	<p class="pb-5 pb-4 col-span-2">A través de dos sensores diferentes se mide la humedad del suelo a nivel de superficie.</p>
				<div>
					<p class="font-semibold">Sensor SMOS</p>
					<p class="pb-5 pb-4">Periodicidad diaria</p>
					<p class="pb-5">Resolución espacial de 43 km, resampleado a 2 kms.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
				<div>
					<p class="font-semibold">Sensor ASCAT</p>
					<p class="pb-5 pb-4">Periodicidad diaria</p>
					<p class="pb-5">Resolución de 25 kms.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-8">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
				<span class="text-2xl font-semibold" style="color: #081414">NDVI - Índice Diferencial de Vegetación Normalizada</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5 pb-4">Indicador del vigor de la vegetación presente en las coberturas del suelo.</p>
					<p class="pb-5 pb-4">Colabora con la selección de áreas aptas para cultivo.</p>
					<p class="pb-5 pb-4">Sus medias históricas pueden ser usadas como indicadores de mayor o menor productividad, así como también de regímenes de sequía.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
		<h2 id="accordion-collapse-heading-9">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
				<span class="text-2xl font-semibold" style="color: #081414">NDWI - Índice Diferencial de Agua Normalizada</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5 pb-4">Indicador del estrés hídrico de los cultivos.</p>
					<p class="pb-5 pb-4">Colabora con la vigilancia y la alerta temprana de fenómenos de sequía.</p>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'Agrotool',
	data() {
		return {
			cssProps: {
				'backgroundImage': `url(${require('@/assets/agrotool-bg.png')})`,
			}
		}
	}
}
</script>

<style scoped>
.rectangle {
	height: 300px;
	width: 550px;
	background-color: #081414;
}
</style>
