<template>
	<header-app/>
  <router-view/>
	<footer-app/>
</template>

<script>
import headerApp from "@/components/headerApp";
import footerApp from "@/components/footerApp"
export default {
	components: {
		headerApp,
		footerApp
	}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
