<template>
	<div class="bg-cover bg-no-repeat" :style="cssProps">
	  <div class="container mx-auto px-6 pb-10 pt-44">
			<img class="w-32 py-8 pt-96" src="@/assets/healthtool.png" alt="image">
			<p class="text-white font-bold text-7xl">Healthtool</p>
	  </div>
	</div>

	<div class="container mx-auto px-6 pb-10 mt-8" id="accordion-collapse" data-accordion="collapse">
		<h2 id="accordion-collapse-heading-1">
			<button type="button" class="flex items-center focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 justify-between p-5 w-full font-medium text-left border border-gray-200 dark:border-gray-700 border-b-0 text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-t-xl" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
				<span class="text-2xl font-semibold" style="color: #081414">Mapa ambiental para la identificación del desarrollo de enfermedades</span>
				<svg data-accordion-icon class="w-6 h-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
			</button>
		</h2>
		<div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
			<div class="grid grid-cols-2 gap-4 p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900 border-b-0">
				<div>
					<p class="pb-5">A partir de diferentes variables atmosféricas, pueden identificarse las áreas con mayor o menor riesgo de propagación del vector que transmite el mosquito del tipo Aedes Aegypti, detectando así el posible desarrollo de enfermedades como dengue, zika y chikungunya.</p>
					<p class="pb-5">Permite agregar diferentes capas de datos relevantes para mejorar la toma de decisiones sobre los riesgos de dengue:</p>
					<ul class="list-disc pl-5">
						<li>Hacinamiento</li>
						<li>Necesidades Básicas Insatisfechas NBI</li>
						<li>Localización de policlínicas</li>
						<li>Censos sobre casos de la enfermedad</li>
						<li>Existencia de larvas</li>
					</ul>
				</div>
				<div>
					<div class="rectangle"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Healthtool',
	data() {
		return {
			cssProps: {
				'backgroundImage': `url(${require('@/assets/healthtool-bg.png')})`,
			}
		}
	}
}
</script>

<style scoped>
.rectangle {
	height: 300px;
	width: 550px;
	background-color: #081414;
}
</style>
