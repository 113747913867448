<template>
	<footer style="background-color: #081414">
		<div class="px-6 pt-20">
			<div class="container mx-auto px-6 pb-10 ">
				<h1 class="text-5xl text-white font-bold pb-3">Contactanos</h1>
				<p class="text-3xl text-white pb-6">Empezá a vivir la experiencia Toolsat</p>
				<div class="container flex">
					<div class="container flex justify-start">
						<p class="text-1xl text-white font-bold">Toolsat</p>
					</div>
					<div class="container flex justify-end">
						<a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><img style="width: 30px" src="@/assets/facebook.png" alt="image"></a>
						<a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" class="pl-4"><img style="width: 30px" src="@/assets/twitter.png" alt="image"></a>
						<a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" class="pl-4"><img style="width: 30px" src="@/assets/instagram.png" alt="image"></a>
						<a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer" class="pl-4"><img style="width: 35px; height: 27px" src="@/assets/youtube.png" alt="image"></a>
					</div>
				</div>
				<p class="text-1xl text-white">Sistema integrado de información satelital</p>
				<p class="text-1xl text-white">correo@correo.org</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "footerApp"
}
</script>

<style scoped>

</style>
